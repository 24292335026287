import { DashboardHeader } from '../components/DashboardHeader';
import DashboardDrawer from '../components/DashboardDrawer';
import { Grid } from '@material-ui/core'
import { useContext, useEffect, useState } from "react";

import { UserContext } from '../App';
import MediaTab from '../components/MediaTab';
import PlaylistTab from '../components/PlaylistTab';
import DevicesTab from '../components/DevicesTab';

export function Dashboard(props) {

    const { company } = useContext(UserContext);

    const [media, setmedia] = useState([])
    const [Playlists, setPlaylists] = useState([])
    const [Devices, setDevices] = useState([])

    useEffect(() => {
        const media_unsubscribe = props.firestore.collection("media").where("user", "==", company.id)
            .onSnapshot((querySnapshot) => {
                let result = [];
                querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    let data_item = doc.data()
                    data_item.id = doc.id;
                    result.push(data_item);
                });
                setmedia(result)
            })
        const playlist_unsubscribe = props.firestore.collection('playlists').where("companyId", "==", company.id)
            .onSnapshot((querySnapshot) => {
                let result = []
                querySnapshot.forEach(function (doc) {
                    let data_item = doc.data()
                    data_item.id = doc.id
                    result.push(data_item)
                })
                setPlaylists(result)
            })
        const devices_unsubscribe = props.firestore.collection('devices').where('companyID', '==', company.id)
            .onSnapshot((querySnapshot) => {
                let result = []
                querySnapshot.forEach(function (doc) {
                    let data_item = doc.data()
                    data_item.id = doc.id
                    result.push(data_item);
                })
                setDevices(result);
            })
        return () => {
            media_unsubscribe()
            playlist_unsubscribe()
            devices_unsubscribe()
        }
    }, [])

    const [selectedDrawerIndex, setselectedDrawerIndex] = useState('media')

    const handleDraweItemClick = (event, index) => {
        setselectedDrawerIndex(index);
    }


    return (
        <>
            <DashboardHeader />
            <DashboardDrawer handleDraweItemClick={handleDraweItemClick} playlists={Playlists} selectedDrawerIndex={selectedDrawerIndex} />
            {selectedDrawerIndex === 'media' ? (
                <MediaTab media={media} />
            ) : selectedDrawerIndex === 'devices' ? (
                    <DevicesTab devices={Devices} playlists={Playlists} />
            ) : <></>}
            {Playlists.map((item) => (
                <>
                    {selectedDrawerIndex === item.id ? (<PlaylistTab media={media} item={item} />) : (<></>)}
                </>
            ))}
        </>
    );
}