import { GridList, GridListTile, GridListTileBar, IconButton, makeStyles, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { UserContext } from "../App";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.background.default,
        width: `calc(100% - ${theme.size.drawerWidth})`,
        height: `calc(100vh - ${theme.size.appBarHeight})`,
        marginTop: theme.size.appBarHeight,
        marginLeft: theme.size.drawerWidth,
    },
    tile: {
        borderRadius: '6px',
    },
    ActionArea: {
        display: 'flex',
        flexDirection: 'row',
    },
    ActionAreaTitle: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
}))

export default function MediaTab(props) {
    const classes = useStyles()
    const { firebase, firestore } = useContext(UserContext)

    const deleteMedia = (id, path) => {
        firebase.storage().ref(path).delete().then(() => {
            firestore.collection('media').doc(id).delete().then(() => {

            }).catch((err) => {

            })
        }).catch((err) => {
        })
    }

    return (
        <div className={classes.background}>
            <GridList cellHeight={200} cols={4}>
                {props.media.map((item) => (
                    <GridListTile classes={{ tile: classes.tile }}>
                        <img src={item.downloadURL} />
                        <GridListTileBar
                            actionIcon={
                                <div className={classes.ActionArea}>
                                    <Typography variant="subtitle1" className={classes.ActionAreaTitle}>{item.description}</Typography>
                                    <IconButton onClick={() => { deleteMedia(item.id, item.filepath) }}>
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </div>
                            }
                        >

                        </GridListTileBar>
                    </GridListTile>
                ))}
            </GridList>
        </div>
    )
}
