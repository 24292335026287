import React, { useContext, useState, useEffect } from 'react'
import { Card, CardContent, Typography, makeStyles, Select, MenuItem, IconButton, CardActions, TextField } from '@material-ui/core'
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import EditIcon from '@material-ui/icons/Edit';
import { UserContext } from '../App';
import DeleteDialog from './DeleteDialog'
import EdiText from 'react-editext'
import PlusOneIcon from '@material-ui/icons/PlusOne';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
    card: {
        width: '30%',
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
    }
}))



export default function DeviceCard(props) {
    const classes = useStyles()

    const { firestore } = useContext(UserContext)

    const [Playlists, setPlaylists] = useState(props.data.assigned_playlists)

    useEffect(() => {
        setPlaylists(props.data.assigned_playlists)
    }, [props.data.assigned_playlists])

    const deleteDevice = () => {
        firestore.collection('devices').doc(props.data.id).delete();
    }

    const changeCurrentPlaylist = (value, index) => {
        const data = [...Playlists]
        data[index].listId = value;
        setPlaylists(data)
    }

    const changeCurrentTime = (value, index) => {
        const data = [...Playlists]
        data[index].time = value;
        setPlaylists(data)
    }

    const changeDeviceName = (val) => {
        firestore.collection('devices').doc(props.data.id).update({ name: val })
    }

    const addPlaylist = () => {
        const data = [...Playlists];
        data.push({ listId: '', time: '' })
        setPlaylists(data)
    }

    const removePlaylist = (index) => {
        const data = [...Playlists];
        data.splice(index, 1)
        setPlaylists(data)
    }

    const saveChanges = () => {
        let ids = []
        Playlists.map((e) => {
            ids.push(e.listId)
        })
        firestore.collection('devices').doc(props.data.id).update({ assigned_playlists: Playlists, playlistIds: ids })
    }

    return (
        <Card
            className={classes.card}
        >
            <CardContent className={classes.cardContent}>
                <VideoLabelIcon style={{ alignSelf: 'flex-end' }} />
                <EdiText
                    showButtonsOnHover
                    value={props.data.name}
                    onSave={(val) => changeDeviceName(val)}
                />
                <Typography variant="subtitle">Current Playlist</Typography>
                {Playlists.map((list) => (
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1em' }}>
                        <Select
                            id="playlist-select"
                            value={list.listId}
                            onChange={(event) => { changeCurrentPlaylist(event.target.value, Playlists.indexOf(list)) }}
                            style={{ width: '45%' }}
                        >
                            {props.playlists.map(playlist => (<MenuItem value={playlist.id}>{playlist.name}</MenuItem>))}
                        </Select>
                        <TextField
                            id="time"
                            label="Alarm clock"
                            type="time"
                            defaultValue={list.time}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 300, // 5 min
                            }}
                            style={{ width: '45%' }}
                            onChange={(event) => { changeCurrentTime(event.target.value, Playlists.indexOf(list)) }}
                        />
                        <DeleteDialog
                            style={{ width: '10%' }}
                            delete={() => { removePlaylist(Playlists.indexOf(list)) }}
                        />
                    </div>
                ))}
                <IconButton
                    onClick={() => { addPlaylist() }}
                >
                    <PlusOneIcon />
                </IconButton>
            </CardContent>
            <CardActions>
                <DeleteDialog delete={deleteDevice} />
                <IconButton
                    onClick={saveChanges}
                >
                    <SaveIcon />
                </IconButton>
            </CardActions>
        </Card>
    )
}
