import { makeStyles, Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core'
import React, { useState } from 'react'

import PermMediaIcon from '@material-ui/icons/PermMedia';
import SettingsIcon from '@material-ui/icons/Settings';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: theme.size.drawerWidth,
        background: theme.palette.primary,
        flexShrink: 0,
    },
    drawerPaper: {
        width: theme.size.drawerWidth,
        background: '#475459',
        marginTop: theme.size.appBarHeight, 
    },
    BTNList: {
        overflow: 'auto',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    }
}))

export default function DashboardDrawer(props) {

    const classes = useStyles();

    const [showPlaylists, setshowPlaylists] = useState('false')

    return (
        <Drawer
            variant='permanent'
            className={classes.drawer}
            anchor='left'
            classes={{
                paper: classes.drawerPaper
            }}
        >
            <div className={classes.BTNList}>
                <List component="nav">
                    <ListItem
                        button
                        selected={props.selectedDrawerIndex === 'media'}
                        onClick={(event) => props.handleDraweItemClick(event, 'media')}
                    >
                        <ListItemIcon>
                            <PermMediaIcon />
                        </ListItemIcon>
                        <ListItemText primary="Media" />
                    </ListItem>
                    <ListItem button onClick={() => { setshowPlaylists(!showPlaylists) }}>
                        <ListItemIcon>
                            <FeaturedPlayListIcon />
                        </ListItemIcon>
                        <ListItemText primary="Playlists" />
                        {!showPlaylists ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={!showPlaylists}>
                        <List component="div">
                            {props.playlists.map((item) => (
                                <ListItem
                                    button
                                    selected={props.selectedDrawerIndex === item.id}
                                    onClick={(event) => props.handleDraweItemClick(event, item.id)}
                                    className={classes.nested}
                                >
                                    <ListItemIcon>
                                        <RemoveFromQueueIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} />
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                    <ListItem
                        button
                        selected={props.selectedDrawerIndex === 'devices'}
                        onClick={(event) => props.handleDraweItemClick(event, 'devices')}
                    >
                        <ListItemIcon>
                            <RemoveFromQueueIcon />
                        </ListItemIcon>
                        <ListItemText primary="Geräte" />
                    </ListItem>
                    <ListItem
                        button
                        selected={props.selectedDrawerIndex === 'settings'}
                        onClick={(event) => props.handleDraweItemClick(event, 'settings')}
                    >
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Einstellungen" />
                    </ListItem>
                </List>
            </div>
        </Drawer>
    )
}
