/* eslint-disable */


import firebase from 'firebase/app';
import 'firebase/storage'
import 'firebase/firestore';
import 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

//Importing Pages
import { LogIn } from './pages/LogIn';
import { Dashboard } from './pages/Dashboard';

//Importing everything to set up Context
import { createContext, useEffect, useState } from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

//Initializing Firebase with App Credentials
firebase.initializeApp({
  apiKey: "AIzaSyD5i8c-PGcuRCdnoQA75x7csFXEnm6dy2I",
  authDomain: "digitale-werbetafel.firebaseapp.com",
  databaseURL: "https://digitale-werbetafel.firebaseio.com",
  projectId: "digitale-werbetafel",
  storageBucket: "digitale-werbetafel.appspot.com",
  messagingSenderId: "954713794555",
  appId: "1:954713794555:web:c584849c23c7a8d4d70d5e",
  measurementId: "G-G8KSFXBV73"
});

//Setting up a reference for Firebase Authentication and Firestore
const auth = firebase.auth();
const firestore = firebase.firestore();

// if (location.hostname === "localhost") {
//   firestore.useEmulator("localhost", 8080);
// }

//Creating the user Context
export const UserContext = createContext();


function App() {
  const [user] = useAuthState(auth)
  const [company, setcompany] = useState()

  useEffect(() => {
    if (user)
      firestore.collection('companys').where('authIds', "array-contains", user.uid).get().then(function (querySnapshot) {
        let data = [];
        querySnapshot.forEach(doc => {
          let dat = doc.data()
          dat.id = doc.id;
          data.push(dat)
        })
        if (data.length > 1)
          console.error('One Account with 2 companies')
        else
          setcompany(data[0])
        console.log(data[0]);
      })
  }, [user])

  const handleLogIn = (email, password) => {
    auth.signInWithEmailAndPassword(email, password).catch(function (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      if (errorCode === 'auth/wrong-password') {
        alert('Wrong password.');
      } else {
        alert(errorMessage);
      }
      console.log(error);
    })
  }


  const backend = () => (
    <UserContext.Provider value={{ user, firebase, firestore, auth, company }}>
      {company ? <Dashboard firebase={firebase} firestore={firestore} /> : <LogIn handleLogIn={handleLogIn} />}
    </UserContext.Provider>
  );

  return (
    <Router>
      <div>
        <Switch>
          <Route path="/" component={backend} />
        </Switch>
      </div>
    </Router>
  )
}


export default App;
