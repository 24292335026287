import React, { useState, useEffect, useContext } from 'react'
import { makeStyles, Grid, Typography, Button, Slider } from '@material-ui/core'
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import PlaylistTabElement from './PlaylistTabElement';
import AddMediaToList from './AddMediaToList';
import { UserContext } from '../App';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteDialog from './DeleteDialog';
import Popup from './Popup';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PlaylistPreview from './PlaylistPreview';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.background.default,
        width: `calc(100% - ${theme.size.drawerWidth})`,
        marginLeft: theme.size.drawerWidth,
        marginTop: theme.size.appBarHeight,
        height: `calc(100vh - ${theme.size.appBarHeight})`,

    },
    Header: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: theme.palette.background.paper,
        height: '3rem',
    },
    HeaderChild: {
        display: 'inline-flex',
    },
    AddBTN: {
        flex: 1,
    },
    BottomMenu: {
        justifyContent: 'flex-end',
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
        height: '4rem',
        alignSelf: 'flex-end'
    },
    root: {
        height: `calc(100vh - ${theme.size.appBarHeight})`,
        overflow: 'hidden',
    },
    EditArea: {
        padding: theme.spacing(1),
        width: '100%',
        height: '94%',
    },
    list: {
        listStyle: 'none',
    },
    PlaylistName: {
        flex: 1,
    },
    sidebar: {
        height: '100%',
        width: '100%',
    },
    DragDropArea: {
        overflow: 'scroll',
        height: `calc(100vh - ${theme.size.appBarHeight} - 3rem)`,
        overflowX: 'hidden',
        padding: theme.spacing(0.5),
    }
}))

export default function PlaylistTab(props) {

    const classes = useStyles();

    const { firestore } = useContext(UserContext)

    const [ListMedia, setListMedia] = useState(props.item.Media)
    const [toEdit, settoEdit] = useState()
    const [SliderVal, setSliderVal] = useState(5)
    const [SucessBarOpen, setSucessBarOpen] = useState(false)

    useEffect(() => {
        if (toEdit && toEdit.type !== 'video/mp4')
            setSliderVal(toEdit.duration)
    }, [toEdit])

    useEffect(() => {
        setListMedia(props.item.Media);
    }, [props])

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(ListMedia);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setListMedia(items);
    }

    const handleSucessBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSucessBarOpen(false);
    };

    const updateFieldChanged = (index, val) => {
        let newArr = [...ListMedia]; // copying the old datas array
        newArr[index].duration = val; // replace e.target.value with whatever you want to change it to
        setListMedia(newArr); // ??
    }

    const saveChanges = () => {
        firestore.collection('playlists').doc(props.item.id).update({ Media: ListMedia }).then(() => {
            setSucessBarOpen(true);
        })
    }

    const deletePlaylist = () => {
        firestore.collection('playlists').doc(props.item.id).delete();
    }

    const delteMedia = (index) => {
        const items = Array.from(ListMedia);
        items.splice(index, 1);
        setListMedia(items);
    }

    return (
        <div className={classes.background}>
            <Snackbar open={SucessBarOpen} autoHideDuration={6000} onClose={handleSucessBarClose}>
                <Alert onClose={handleSucessBarClose} severity="success">
                    Gespeichert!
                </Alert>
            </Snackbar>
            <Grid container className={classes.root}>
                <Grid
                    item
                    container
                    xs={12}
                    className={classes.Header}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item xs={12} className={classes.HeaderChild}>
                        <Typography align="center" variant="h4" className={classes.PlaylistName}>{props.item.name}</Typography>
                        <Popup Icon={(<PlayArrowIcon />)} > <PlaylistPreview list={ListMedia} /> </Popup>
                        <AddMediaToList media={props.media} listID={props.item.id} className={classes.AddBTN} />
                        <DeleteDialog delete={deletePlaylist} />
                    </Grid>
                </Grid>
                <Grid item xs={5} className={classes.DragDropArea}>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="Media">
                            {(provided) => (
                                <ul className={"Media", classes.list} {...provided.droppableProps} ref={provided.innerRef}>
                                    {ListMedia !== undefined ? ListMedia.map((media, index) => (
                                        <PlaylistTabElement edit={() => settoEdit(index)} id={media.id} media={media} index={index} delete={() => delteMedia(index)} />
                                    )) : (<></>)}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Grid>
                <Grid item xs={7} className={classes.sidebar}>
                    <Grid item container xs={12} className={classes.EditArea}>
                        {ListMedia[toEdit] && ListMedia[toEdit].type != 'video/mp4' ? (
                            <Grid
                                item
                                container
                                direction="row"
                                alignItems="center"
                                justify="center"
                                textAlign="center"
                            >
                                <Grid item xs={12}>
                                    <Typography variant="h4" align="center">
                                        {ListMedia[toEdit].description}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} className={classes.durationContainer}>
                                    <Typography>Bildschirmzeit</Typography>
                                    <Slider
                                        value={ListMedia[toEdit].duration}
                                        name="duration"
                                        onChange={(e, val) => updateFieldChanged(toEdit, val)}
                                        step={1}
                                        min={1}
                                        max={60}
                                        aria-labelledby="discrete-slider"
                                        valueLabelDisplay="auto"
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                                <Grid item xs={12}>
                                    <Typography variant="body1" align="center">Wähle eine Element zum bearbeiten aus.</Typography>
                                </Grid>
                            )}
                        <Grid item xs={12} className={classes.BottomMenu}>
                            <Button onClick={saveChanges} variant="contained">Speichern</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
