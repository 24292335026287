import { Dialog, DialogActions, DialogContent, IconButton, DialogTitle, DialogContentText, TextField, Button } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { ExitToAppOutlined } from '@material-ui/icons';
import { UserContext } from '../App';

export default function AddPlaylist() {

    const [AddPlaylistModalOpen, setAddPlaylistModalOpen] = useState(false)
    const [PlaylistName, setPlaylistName] = useState('')

    const { firestore, company } = useContext(UserContext)

    const createPlaylist = () => {
        firestore.collection('playlists').add({
            name: PlaylistName.toString(),
            companyId: company.id,
            Media: [],
        }).then(() => {
            setPlaylistName('')
            setAddPlaylistModalOpen(false)            
        }).catch((error) => {
            console.error(error.message)
        })

    }

    return (
        <>
            <IconButton onClick={() => setAddPlaylistModalOpen(true)}>
                <PlaylistAddIcon />
            </IconButton>
            <Dialog open={AddPlaylistModalOpen}>
                <DialogTitle>Neue Playlist erstellen</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Hier kannst du eine neue Playlist anlegen.
                        Diese wird links unter dem Menüpunkt Playlisten aufgeführt, wo du weitere Einstellungen vornehmen kannst.
                        Zum erstellen lege bitte einen Namen fest. Diesen kannst du im Nachhinein nicht ändern.
                        </DialogContentText>
                    <TextField
                        id="PlaylistName"
                        label="Name deiner Playlist"
                        type="text"
                        onChange={(event) => setPlaylistName(event.target.value)}
                        fullwidth
                    />
                </DialogContent>
                <DialogActions>
                    <IconButton onClick={() => setAddPlaylistModalOpen(false)}>
                        <ExitToAppOutlined />
                    </IconButton>
                    <Button onClick={() => createPlaylist()}>Playlist erstellen</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
