import React, { useContext, useState } from 'react'
import { Button, Dialog, DialogContentText, DialogActions, DialogContent, GridList, GridListTile, GridListTileBar, IconButton, makeStyles } from '@material-ui/core'
import QueueIcon from '@material-ui/icons/QueueOutlined';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { UserContext } from '../App';

const useStyles = makeStyles((theme) => ({
    main: {
        height: "80%",
    },
    img:{
        objectFit: 'contain'
    }
}))

export default function AddMediaToList(props) {
    const classes = useStyles()
    const { firestore, firebase } = useContext(UserContext)

    const ImportMedia = (media, listID) => {
        if (media.type !== 'video/mp4')
            media.duration = 5;
        firestore.collection('playlists').doc(listID).update({
            Media: firebase.firestore.FieldValue.arrayUnion(media),
        });
    }

    const [DialogOpen, setDialogOpen] = useState(false)

    return (
        <>
            <IconButton onClick={() => setDialogOpen(true)}>
                <QueueIcon />
            </IconButton>

            <Dialog className={classes.main} open={DialogOpen}>
                <DialogContent className={classes.main}>
                    <DialogContentText>
                        Hier kannst du Medien zu deiner Playlist hinzufügen.
                    </DialogContentText>
                    <div>
                        <GridList cellHeight={300} cols={2}>
                            {props.media.map(({ downloadURL, id, description, type, filepath }) => (
                                <GridListTile key={id}>
                                    <img className={classes.img} src={downloadURL} />
                                    <GridListTileBar
                                        title={description}
                                        actionIcon={
                                            <IconButton onClick={() => ImportMedia({ id, downloadURL, description, type, filepath }, props.listID)}>
                                                <SystemUpdateAltIcon />
                                            </IconButton>
                                        }
                                    >

                                    </GridListTileBar>
                                </GridListTile>
                            ))}
                        </GridList>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => setDialogOpen(false)} >Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
