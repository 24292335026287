import React, { useState, useEffect } from 'react'
import MediaShower from './MediaShower'

import { LinearProgress } from '@material-ui/core';

const sleep = (milliseconds) => {
    console.log(milliseconds);
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export default function PlaylistPreview(props) {
    const [Src, setSrc] = useState()
    const [Type, setType] = useState()
    //const [MediaList, setMediaList] = useState([])
    const [isPlaying, setIsPlaying] = useState(false)
    const [hasData, setHasData] = useState(false)
    const [progress, setProgress] = useState(0)

    const showM = async (MediaList) => {
        for (let item of MediaList) {
            setProgress(0)
            let src = item.downloadURL;
            setSrc(src)
            setType(item.type)
            for (let i = 0; i < (item.duration * 2); i++) {
                await sleep(500)
                setProgress((i / (item.duration * 2)) * 100)
            }
            if (item == (MediaList.length - 1))
                return setIsPlaying(false)
        }
    }

    useEffect(async () => {
        console.log('isPlaying: ', isPlaying);
        if (isPlaying == true)
            return;
        let list = props.list;
        while (!list) {
            list = props.list;
            await sleep(1000)
        }
        setHasData(true);
        showM(list);
        setIsPlaying(true);
    }, [isPlaying])

    return (
        <>
            {hasData ? (
                <>{Src && Type ? (<><MediaShower src={Src} type={Type} /> <LinearProgress variant="determinate" value={progress} /> </>) : null}</>
            ) : <h3 style={{ textAlign: 'center', verticalAlign: 'middle', margin: 0, color: '#F2E205' }}>Keine Daten vorhanden...</h3>}
        </>
    )
}
