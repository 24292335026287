import { Button, IconButton, Popover } from '@material-ui/core'
import React, { useState } from 'react'

export default function Popup(props) {
    const [open, setOpen] = useState(false)
    

    return (
        <div>
            <IconButton onClick={() => { setOpen(!open) }}>
                {props.Icon}
            </IconButton>
            <Popover
                open={open}
                anchorPosition="bottom"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div>
                    {props.children}
                    <div style={{ display: 'block' }}>
                        <Button onClick={() => setOpen(false)}>Schließen</Button>
                    </div>
                </div>
            </Popover>
        </div>
    )
}