import { makeStyles, Grid } from '@material-ui/core'
import React from 'react'
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import DeviceCard from './DeviceCard';


const useStyles = makeStyles((theme) => ({
    background: {
        backgroundColor: theme.palette.background.default,
        width: `calc(100% - ${theme.size.drawerWidth})`,
        height: `calc(100vh - ${theme.size.appBarHeight})`,
        marginTop: theme.size.appBarHeight,
        marginLeft: theme.size.drawerWidth,
    },
}))


export default function DevicesTab(props) {
    const classes = useStyles()
    return (
        <div className={classes.background}>
            <Grid container >
                {props.devices.map(device => (
                    <DeviceCard data={device} playlists={props.playlists} />
                ))}
            </Grid>
        </div>
    )
}
