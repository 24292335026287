import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#F2E205',
        },
        secondary: {
            main: '#475459',
        },
        background: {
            default: '#475459',
            paper: '#93B7BF',
        }, 
    },
    size: {
        drawerWidth: '300px',
        appBarHeight: '4rem',
    },
    overrides:{
        MuiCardMedia:{
            media:{
                width: 'auto',
            }
        }
    }
});

export default theme;