import { Card, Typography, CardActions, CardMedia, makeStyles, IconButton } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useEffect, useContext } from 'react'
import { Draggable } from 'react-beautiful-dnd';
import DeleteDialog from './DeleteDialog';

const useStyles = makeStyles((theme) => ({
    image: {
        objectFit: 'contain'
    },
    root: {
        marginBottom: theme.spacing(1),
        display: 'flex',
    },
    Card:{
        display: 'flex',
        height: '16em',
        justifyContent: 'space-between',
    },
    div:{
        padding: theme.spacing(2)
    },
    text:{
        color: '#475459'
    }
}))

export default function PlaylistTabElement(props) {

    const classes = useStyles()

    return (
        <Draggable key={props.media.id} draggableId={props.media.id} index={props.index}>
            {(provided) => (
                <li className={classes.root} key={props.media.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <Card className={classes.Card}>
                        <div className={classes.div}>
                                <Typography variant="caption" className={classes.text}>{props.media.description}</Typography>
                                {props.media.type !== "video/Fmp4" ? <Typography className={classes.text} variant="body2">Spieldauer: {props.media.duration} sekunden</Typography> : <></>}
                            <CardActions>
                                <IconButton onClick={props.edit}>
                                    <Edit />
                                </IconButton>
                                <DeleteDialog delete={props.delete} />
                            </CardActions>
                        </div>
                        <CardMedia
                            classes={classes.image}

                            image={props.media.downloadURL}
                            component="img"
                            title={props.media.description}
                        />
                    </Card>
                </li>
            )}
        </Draggable>
    )
}
