import { AppBar, IconButton, makeStyles, Toolbar, Typography } from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';

import { UserContext } from '../App';
import UploadMediaDialog from "./UploadMediaDialog";
import { useState, useContext } from "react";
import AddPlaylist from "./AddPlaylist";

const useStyles = makeStyles((theme) => ({
    LogOutBTN: {
        marginRight: theme.spacing(2)
    },
    Title: {
        flexGrow: 1,
    },
    main: {
        //width: `calc(100% - ${theme.size.drawerWidth})`,
        //marginLeft: theme.size.drawerWidth,
        boxSizing: 'border-box',
        height: theme.size.appBarHeight,
        backgroundColor: theme.palette.background,
        zIndex: theme.zIndex.drawer + 1,
    }
}))

export function DashboardHeader(props) {
    const { company, auth } = useContext(UserContext);
    const classes = useStyles(props);
    const [OpenMediaUpload, setOpenMediaUpload] = useState(false)

    return (
        <AppBar position="fixed" color="secondary" className={classes.main}>
            <Toolbar>
                <Typography variant="h4" className={classes.Title}>Dashboard</Typography>
                <Typography variant="h6" className={classes.Title}>Eingeloggt als: {company.name}</Typography>
                <AddPlaylist />
                <IconButton onClick={() => { setOpenMediaUpload(true) }} className={classes.LogOutBTN}>
                    <AddPhotoAlternateIcon />
                </IconButton>
                <UploadMediaDialog open={OpenMediaUpload} close={() => { setOpenMediaUpload(false) }} />
                <IconButton onClick={() => auth.signOut().then(()=> {window.location.reload()})} className={classes.LogOutBTN}>
                    <ExitToAppIcon />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
}