import React from 'react';

export default function MediaShower(props) {
    return (
        <div style={{ backgroundImage: `url(${props.src})` , height: '9em', width: '16em', zIndex: 9999, backgroundSize: 'contain', display: 'block', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
            {/* {props.type == 'video/mp4' ? (
                <video autoPlay muted
                    style={{
                        width: "100vw",
                        height: "100vh",
                        objectFit: "cover",
                        overflow: 'hidden',
                    }}
                >
                    <source src={props.src} type="video/mp4"></source>
                </video>
            ) : (
                    <img
                        style={{
                            minWidth: "16em",
                            minHeight: "9em",
                            objectFit: "contain",
                            
                        }}
                        src={props.src}
                    >

                    </img>
                )} */}

        </div>
    )
}
