import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, makeStyles, Slider, TextField, Typography } from '@material-ui/core'
import React, { useState, useContext } from 'react'
import { UserContext } from '../App'
import firebase from 'firebase/app';
import { Warning } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    }
}))

export default function UploadMediaDialog(props) {
    const classes = useStyles();

    const [Description, setDescription] = useState('')
    const [Duration, setDuration] = useState(8)
    const [File, setFile] = useState()
    const [Warning, setWarning] = useState('')

    const { company, firestore } = useContext(UserContext);

    const validateFileType = (filetype) => {
        console.log(filetype)
        switch (filetype) {
            case 'image/png':
                return true;
                break;
            case 'image/jpeg':
                return true;
                break;
            case 'video/x-m4v':
                return true;
                break;
            case 'video/mp4':
                return true;
                break;
            default:
                return false;
                break;
        }
    }

    function UploadFiles(event) {
        event.preventDefault();

        if (!validateFileType(File.type)) {
            setWarning('Wir akzeptieren nur png, jpg, jpeg or mp4 Dateien.')
            return;
        }

        const filename = new Date().getUTCMilliseconds().toString();
        const storage = firebase.storage();
        const path = 'media/' + filename + File.name;
        const storageRef = storage.ref(path);
        storageRef.put(File).then(async (snapshot) => {
            const url = await snapshot.ref.getDownloadURL();

            firestore.collection('media').add({
                user: company.id,
                filepath: path.toString(),
                downloadURL: url,
                description: Description,
                type: File.type
            }).then(() => {
                props.close();
            })
        }).catch(err => {
            console.log(err.message)
        })
    }


    return (
        <Dialog open={props.open}>
            <DialogTitle>Datei Hochladen</DialogTitle>
            <form onSubmit={UploadFiles}>
                <DialogContent>
                    <TextField
                        className={classes.margin}
                        id="description"
                        label="Beschreibung"
                        type="text"
                        fullWidth
                        value={Description}
                        onChange={(event) => { setDescription(event.target.value) }}
                    />
                    <Typography >Anzeigezeit in Sekunden</Typography>
                    <Input
                        className={classes.margin}
                        type="file"
                        name="Datei"
                        onChange={(event) => { setFile(event.target.files[0]) }}
                    />
                    <Typography>{Warning}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" onClick={() => props.close()}>Schließen</Button>
                    <Button type="Submit" variant="contained" color="secondary">Hochladen</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
