import React, { useState } from 'react'
import { Dialog, IconButton, Button, DialogActions, DialogTitle } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';

export default function DeleteDialog(props) {

    const [open, setOpen] = useState(false)

    return (
        <>
            <IconButton>
                <DeleteIcon onClick={() => setOpen(true)} />
            </IconButton>
            <Dialog
                open={open}
            >
                <DialogTitle>Willst du das sicher löschen?</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} autofocus> Abbrechen </Button>
                    <Button onClick={() => { props.delete(); setOpen(false)}}>Ja</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
