import { useState } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    LoginForm: {
        borderRadius: '25px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        marginTop: '2rem',
    },
    background: {
        backgroundColor: theme.palette.background.default,
        height: '100vh',
    }
}));

export function LogIn(props) {

    const classes = useStyles(props);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const LogIn = (event) => {
        event.preventDefault();
        props.handleLogIn(email, password);
    }

    return (
        <Grid container className={classes.background}>
            <Grid item container className={classes.container}>
                <Grid item xs={2} />
                <Grid item xs={8} className={classes.LoginForm}>
                    <form onSubmit={LogIn}>
                        <TextField
                            id="E-Mail"
                            type='email'
                            label="E-Mail"
                            variant="outlined"
                            fullWidth
                            margin='normal'
                            onChange={(event) => setEmail(event.target.value)}
                            value={email}
                        />
                        <TextField
                            id="Password"
                            type='password'
                            label="Passwort"
                            variant="outlined"
                            fullWidth
                            margin='normal'
                            onChange={(event) => setPassword(event.target.value)}
                            value={password}
                        />

                        <Button
                            color='primary'
                            type='Submit'
                            size='large'
                            variant='contained'
                            fullWidth
                            margin='normal'
                        >
                            Anmelden
                        </Button>
                    </form>
                </Grid>
                <Grid item xs={2} />
            </Grid>
        </Grid>
    );
}